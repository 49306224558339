import { loginWithEmail, loginWithFacebook, loginWithGoogle } from "@/api/auth/firebase"
import { useRegisterPlayer } from "@/api/auth/hooks"
import { useAuthContext } from "@/components/core/Auth/AuthProvider"
import { FirebaseErrorsMessages } from "@/components/core/Auth/messages"
import { ROUTES } from "@/conf"
import { FirebaseError } from "firebase/app"
import { useTranslation } from "next-i18next"
import { useRouter, useSearchParams } from "next/navigation"
import { useState } from "react"
import { useForm } from "react-hook-form"

enum FormKeys {
  EMAIL = "email",
  PASSWORD = "password",
}
interface FormData {
  [FormKeys.EMAIL]: string
  [FormKeys.PASSWORD]: string
}

const DEFAULT_VALUES: FormData = { [FormKeys.EMAIL]: "", [FormKeys.PASSWORD]: "" }

const useLogin = () => {
  const form = useForm<FormData>({ defaultValues: DEFAULT_VALUES, mode: "onBlur" })
  const {
    formState: { dirtyFields, isValid },
  } = form
  const { push } = useRouter()
  const params = useSearchParams()
  const { t } = useTranslation(["auth", "validations"])
  const [globalError, setGlobalError] = useState("")
  const { mutateAsync: registerWithBE } = useRegisterPlayer()
  const from = params.get("from") || ROUTES.root
  const { setUser } = useAuthContext()

  const isDirty = Object.keys(dirtyFields).length === Object.keys(DEFAULT_VALUES).length
  const enableSubmit = isDirty && isValid

  const handleError = (error: string | null) => {
    switch (error) {
      case FirebaseErrorsMessages.WRONG_PASSWORD:
      case FirebaseErrorsMessages.INVALID_CREDENTIALS: {
        form.setError(
          FormKeys.EMAIL,
          {
            message: t("validations:credentials.incorrect"),
          },
          { shouldFocus: true },
        )
        form.setError(FormKeys.PASSWORD, {
          message: t("validations:credentials.incorrect"),
        })
        break
      }
      case FirebaseErrorsMessages.USER_NOT_FOUND: {
        form.setError(
          FormKeys.EMAIL,
          {
            message: t("validations:credentials.incorrect"),
          },
          { shouldFocus: true },
        )
        break
      }
      case FirebaseErrorsMessages.ALREADY_IN_USE: {
        form.setError(
          FormKeys.EMAIL,
          {
            message: t("validations:credentials.alreadyInUse"),
          },
          { shouldFocus: true },
        )
        break
      }
      case FirebaseErrorsMessages.TOO_MANY_REQUESTS: {
        setGlobalError(t("validations:credentials.blocked"))
        break
      }
      default:
        form.setError(
          FormKeys.EMAIL,
          {
            message: t("validations:credentials.loginFailed"),
          },
          { shouldFocus: true },
        )
        break
    }
  }

  const onSubmit = async (formData: FormData) => {
    const { data, error } = await loginWithEmail(formData.email, formData.password)

    if (data) {
      setUser(data.user)
      push(from)
    }

    handleError(error)
  }

  const onGoogleLogin = async () => {
    try {
      await loginWithGoogle()
      await registerWithBE({})
      push(from)
    } catch (e) {
      if (e instanceof FirebaseError) {
        handleError(e.code)
      } else {
        handleError(e)
      }
    }
  }

  const onFacebookLogin = async () => {
    try {
      await loginWithFacebook()
      await registerWithBE({})
      push(from)
    } catch (e) {
      if (e instanceof FirebaseError) {
        handleError(e.code)
      } else {
        handleError(e)
      }
    }
  }

  return { globalError, enableSubmit, onSubmit, form, onGoogleLogin, onFacebookLogin }
}

export default useLogin
