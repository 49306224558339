import AuthLayoutContext from "@/components/layouts/AuthLayoutContext"
import Logo from "@/components/shared/Logo"
import RoundedButton from "@/components/shared/RoundedButton"
import Typography from "@/components/shared/Typography"
import { ROUTES } from "@/conf"
import Image from "next/image"
import Link from "next/link"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { twMerge } from "tailwind-merge"

interface AuthLayoutProps {
  withRightImage?: boolean
  withPartnersLogo?: boolean
  children: React.ReactNode
}

const AuthLayout = ({ children, withRightImage = false, withPartnersLogo = true }: AuthLayoutProps) => {
  const { t } = useTranslation("common")
  const [isEnabledBackButton, setIsEnabledBackButton] = useState(false)
  const [isWithRightImage, setIsWithRightImage] = useState(withRightImage)
  const [onClickBackButton, setOnClickBackButton] = useState<() => void>(() => {})

  const updateOnClickBackButton = useCallback((newOnClickBackButton: () => void) => {
    setOnClickBackButton(() => newOnClickBackButton)
  }, [])

  return (
    <AuthLayoutContext.Provider
      value={{
        isEnabledBackButton,
        onClickBackButton,
        updateOnClickBackButton,
        setIsEnabledBackButton,
        setIsWithRightImage,
        isWithRightImage,
      }}
    >
      <div className="relative flex min-h-dvh flex-col justify-between bg-primary-700">
        {isWithRightImage && (
          <div className="absolute right-0 top-0 hidden h-full w-full max-w-[700px] lg:block">
            <div className="absolute z-10 h-full w-full bg-gradient-to-r from-[#0B0414] from-0% to-[#0b0414]/[0.00] to-100%" />
            <Image src="/assets/images/authBg.png" alt="" fill className="static object-cover" />
          </div>
        )}
        <header className="mx-auto w-full max-w-7xl">
          <div className="px-6 pt-6 lg:pt-10">
            {isEnabledBackButton ? (
              <RoundedButton
                onClick={onClickBackButton}
                icon={<Image src="/assets/images/arrow-left.svg" width={24} height={24} alt="" />}
              />
            ) : (
              <Link href={ROUTES.root}>
                <Logo />
              </Link>
            )}
          </div>
        </header>
        <main className="z-10 px-6">
          <div className="mx-auto w-full max-w-7xl">
            <div
              className={twMerge(
                "mx-auto flex w-full max-w-[386px] items-center justify-center",
                isWithRightImage && "lg:ml-[180px]",
                withPartnersLogo && "mt-8 md:mt-20 lg:mt-10",
              )}
            >
              {children}
            </div>
          </div>
        </main>
        {withPartnersLogo ? (
          <footer className="mx-auto w-full max-w-7xl pb-6 pt-16 md:pb-8 md:pt-[186px] lg:pt-[53px]">
            <div className="flex px-6">
              <Typography type="m" className="text-primary-300">
                {t("footer.partners")}
              </Typography>
              <div className="ml-8 flex gap-x-6">
                <Image alt="Prima Zoom" src="/assets/images/logo-zoom.svg" width={34} height={20} />
                <Image alt="Prima Zoom" src="/assets/images/euromedia.svg" width={116.47} height={15.2} />
              </div>
            </div>
          </footer>
        ) : (
          <footer />
        )}
      </div>
    </AuthLayoutContext.Provider>
  )
}

export default AuthLayout
