import Login from "@/components/core/Auth/Login"
import SEO from "@/components/core/SEO"
import AuthLayout from "@/components/layouts/AuthLayout"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"

const Index = () => {
  return (
    <>
      <SEO />
      <AuthLayout withRightImage>
        <Login />
      </AuthLayout>
    </>
  )
}

export async function getServerSideProps({ locale }) {
  const translations = await serverSideTranslations(locale, ["common", "validations", "auth"])

  return {
    props: {
      ...translations,
    },
  }
}

export default Index
