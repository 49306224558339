import Button from "@/components/shared/Button"
import Image from "next/image"

interface Props {
  onGoogleLogin: () => void
  onFacebookLogin: () => void
}
const SocialLoginButtons = ({ onFacebookLogin, onGoogleLogin }: Props) => {
  return (
    <div className="flex w-full gap-4">
      <Button color="white" type="button" onClick={onGoogleLogin} fullWidth>
        <div className="h-6 w-6">
          <Image src="/assets/images/google.svg" alt="Google" width={24} height={24} />
        </div>
      </Button>

      <Button color="blue" type="button" onClick={onFacebookLogin} fullWidth>
        <div className="h-6 w-6">
          <Image src="/assets/images/facebook.svg" alt="Facebook" width={24} height={24} />
        </div>
      </Button>
    </div>
  )
}

export default SocialLoginButtons
