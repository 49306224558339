import { createContext } from "react"

interface AuthLayoutContextProps {
  isEnabledBackButton: boolean
  isWithRightImage: boolean
  onClickBackButton: () => void
  updateOnClickBackButton: (newOnClickBackButton: () => void) => void
  setIsEnabledBackButton: (isEnabled: boolean) => void
  setIsWithRightImage: (isEnabled: boolean) => void
}

const AuthLayoutContext = createContext<AuthLayoutContextProps>({
  isEnabledBackButton: false,
  isWithRightImage: true,
  onClickBackButton: () => {},
  updateOnClickBackButton: () => {},
  setIsWithRightImage: () => {},
  setIsEnabledBackButton: () => {},
})

export default AuthLayoutContext
