import Link from "next/link"
import React from "react"

type RoundedButtonProps = {
  icon: React.ReactNode
  link?: string
  className?: string
  onClick?: () => void
  color?: string
  size?: string
}

const RoundedButton: React.FC<RoundedButtonProps> = ({
  size = "w-14 h-14",
  color = "bg-primary-600",
  className,
  icon,
  link,
  onClick,
}) => {
  const buttonContent = (
    <button
      onClick={onClick}
      className={`${className} ${color} ${size} flex items-center justify-center rounded-full `}
    >
      {icon}
    </button>
  )

  return link ? <Link href={link}>{buttonContent}</Link> : buttonContent
}

export default RoundedButton
