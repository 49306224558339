import { inputStylingProps } from "@/components/shared/inputs/utils"
import { EMAIL_REGEX } from "@/conf"
import { useTranslation } from "next-i18next"
import { InputField } from "rtu-components"
import Typography from "../Typography"

export const Email = () => {
  const { t } = useTranslation(["auth", "validations"])
  return (
    <InputField
      renderLabel={() => <Typography type="caption">{t("email.label")}</Typography>}
      name="email"
      placeholder={t("email.placeholder")}
      {...inputStylingProps}
      validation={{
        rules: {
          required: { message: t("validations:required"), value: true },
          pattern: {
            message: t("validations:email"),
            value: EMAIL_REGEX,
          },
        },
      }}
    />
  )
}
