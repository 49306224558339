import useLogin from "@/components/core/Auth/Login/useLogin"
import Button from "@/components/shared/Button"
import SocialLoginButtons from "@/components/shared/SocialLoginButtons"
import Typography from "@/components/shared/Typography"
import { Email } from "@/components/shared/inputs/Email"
import { Password } from "@/components/shared/inputs/Password"
import { ROUTES } from "@/conf"
import Link from "next/link"
import { FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"

const Login = () => {
  const { t } = useTranslation(["auth", "validations", "common"])

  const { enableSubmit, form, globalError, onFacebookLogin, onGoogleLogin, onSubmit } = useLogin()

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
        <div className="flex w-full flex-col items-center justify-center lg:items-start">
          <Typography tag="h1" type="h1" className="text-primary-50">
            {t("login.subtitle")}
          </Typography>
          <Typography className="mt-2 text-center text-primary-200 md:mt-4" type="l">
            {t("common:loginMessage")}
          </Typography>

          <div className="mt-10 flex w-full flex-col gap-y-4 md:mt-14 lg:mt-14">
            <Typography className="mb-3 text-error" type="l">
              {globalError}
            </Typography>
            <Email />
            <Password />
          </div>
          <div className="mt-2 w-full">
            <Link href={ROUTES.reset} className="text-primary-700">
              <Typography type="l" className="text-secondary-300">
                {t("password.forgot")}
              </Typography>
            </Link>
            <Button color="primary" className="mb-2 mt-6 w-full" type="submit" disabled={!enableSubmit}>
              {t("login.submit")}
            </Button>

            <div className="mt-8 flex flex-col gap-y-4 md:mt-14">
              <Typography className="text-center text-primary-200" type="l">
                {t("orLoginThrough")}
              </Typography>
              <SocialLoginButtons onFacebookLogin={onFacebookLogin} onGoogleLogin={onGoogleLogin} />
            </div>
          </div>

          <div className="mt-6 flex w-full justify-center gap-1">
            <Typography className="text-primary-200" tag="span" type="l">
              {t("login.prompt")}
            </Typography>

            <Link href={ROUTES.register} className="text-primary-700">
              <Typography className="text-secondary-300" tag="span" type="l">
                {t("login.link")}
              </Typography>
            </Link>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

export default Login
